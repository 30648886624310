import React from 'react';

export const ROOT = React.lazy(() => import('../component/home'));
export const ABOUT_US = React.lazy(() => import('../component/aboutus'));
export const CONTACT_US = React.lazy(() => import('../component/contactus'));
export const PROJECT_DETAILS = React.lazy(() => import('../component/projectDetails'));
export const PROJECT_UNITS = React.lazy(() => import('../component/projectunits'));
export const PROJECT_GALLERY = React.lazy(() => import('../component/gallery'));
export const INDEXS = React.lazy(() => import('../component/indexs/indexs'));
export const INDEXSNAV = React.lazy(() => import('../component/indexs/indexsnav'));
export const INDEXSFOOTER = React.lazy(() => import('../component/indexs/indexsfooter'));
export const INDEXSBANNER = React.lazy(() => import('../component/indexs/indexsbanner'));
export const INDEXSHOMEOTHERS = React.lazy(() => import('../component/indexs/indexshomeothers'));
